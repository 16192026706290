import { ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

interface ButtonMenuProps extends ButtonProps {}

export const StyledLinkButton = styled(Button)(({ theme }) => ({
  margin: 0,
  padding: 0,
  minWidth: 0,
  color: theme.palette.text.primary,
  fontWeight: 400,
}));

export const LinkButton = ({ ...props }: ButtonMenuProps) => {
  return <StyledLinkButton variant="text" {...props} />;
};
