import { getDmiPerHeadByDrops } from '@/common/utils/math';
import { filterPenLotsForDate } from '@/common/utils/penLot.filterPenLots';
import { useIsSuperAdmin } from '@/components/Admin/useIsSuperAdmin';
import { DuplicateDropsEmptyView } from '@/components/CallFeedTab/DuplicateDropsEmptyView';
import { PenDropsList } from '@/components/CallFeedTab/PenDropsList';
import {
  BoxFlexOne,
  CallFeedTabFields,
  CallFeedTabHeader,
  PenDropsListWrap,
} from '@/components/CallFeedTab/styledComponents';
import { CalLFeedFormValues, CallFeedTabProps } from '@/components/CallFeedTab/types';
import { useDialog } from '@/components/Dialog';
import { useEditContext, useEditContextPreventRouteChange } from '@/components/EditContext';
import { UnsavedFlashMessage } from '@/components/UnsavedFlashMessage';
import { UnsavedWarningDialog } from '@/components/UnsavedWarningDialog';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { BunkScoreValue, useUpdateCallFeedMutation } from '@/web-types';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import { sumBy } from 'lodash';
import dynamic from 'next/dynamic';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Virtuoso } from 'react-virtuoso';
import { callFeedKvStore } from './callFeedKvStore';
import { defaultKvStore } from './constants';
import { DateSelect } from '@/components/CallFeedTab/DateSelect';
import { enqueueSnackbar } from 'notistack';
import { CHANGES_SAVED_MESSAGE } from '@/common/messages';
import { showCallFeedSettingsModal } from '@/components/CallFeedTab/CallFeedSettingsModal';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';

export const DevToolDateOverride = dynamic(() => import('@/components/DevToolDateOverride/DevToolDateOverride'), {
  ssr: false,
});

gql`
  mutation UpdateCallFeed($date: LocalDate!, $pens: [InputCallFeedPen!]!) {
    callFeed(date: $date, pens: $pens) {
      id
    }
  }
  mutation DuplicateDropsAndLoads($fromDate: LocalDate!, $toDate: LocalDate!) {
    duplicateDropsAndLoads(fromDate: $fromDate, toDate: $toDate) {
      id
      load {
        id
      }
      pen {
        id
      }
    }
  }
`;

export const CallFeedTab = ({
  canDuplicateYesterdayDrops = false,
  pens = [],
  rations = [],
  dateOptions,
  selectedDate,
  tz,
  stale,
  onDateSelected,
  onSubmit: onSubmitSuccess,
}: CallFeedTabProps): JSX.Element => {
  const isPhoneSize = useIsPhoneSize();
  const unsavedWarningDialog = useDialog();
  const [{ fetching: submitting }, updateCallFeed] = useUpdateCallFeedMutation();

  const formRef = useRef<HTMLFormElement>(null);
  const isFocusOnFirstErrorAfterDialogClose = useRef(false);
  const { setEditing, setPreventEvent, continueEvent } = useEditContext();
  const methods = useForm<CalLFeedFormValues>({
    defaultValues: {
      ...defaultKvStore,
      pens: [],
      detailDialogPenIndex: null,
    },
  });

  const isTodaySelected = useMemo(() => {
    if (selectedDate && dateOptions.length) {
      return selectedDate === dateOptions[0].value;
    }
    return true;
  }, [dateOptions, selectedDate]);

  const isDropsEmptyTomorrow = useMemo(
    () => !isTodaySelected && pens.every((pen) => !pen.drops?.length),
    [isTodaySelected, pens]
  );

  const isSuperAdmin = useIsSuperAdmin();

  const {
    handleSubmit,
    getValues,
    reset,
    setFocus,
    trigger: validateForm,
    formState: { isDirty, errors },
  } = methods;

  const focusOnFirstError = useCallback(() => {
    const firstPen = errors.pens?.findIndex?.((pen) => !!pen);
    if (typeof firstPen === 'number') {
      const firstDrop = errors.pens![firstPen]?.drops?.findIndex?.((drop) => !!drop);
      if (typeof firstDrop === 'number') {
        setFocus(`pens.${firstPen}.drops.${firstDrop}.rationId`);
      }
    }
  }, [errors.pens, setFocus]);

  const focusOnFirstErrorAfterDialogClose = useCallback(() => {
    if (isFocusOnFirstErrorAfterDialogClose.current) {
      focusOnFirstError();
    }
    isFocusOnFirstErrorAfterDialogClose.current = false;
  }, [focusOnFirstError]);

  const onSubmit = useCallback(
    async (data: CalLFeedFormValues) => {
      const pens = data.pens.map((pen) => ({
        penId: pen.id,
        bunkScore: pen.bunkScore?.length ? (pen.bunkScore as BunkScoreValue) : null,
        drops: (pen?.drops ?? [])
          .filter((drop) => drop.rationId)
          .map((drop) => ({
            id: drop.id,
            rationId: drop.rationId as number,
            calledLbs: drop.calledLbs ?? 0,
          })),
      }));
      const response = await updateCallFeed({ date: selectedDate, pens });
      if (response.error) {
        focusOnFirstError();
      } else {
        onSubmitSuccess?.();

        // reset form after the mutation is successful to remove "dirty" state
        reset({ ...getValues() });
        enqueueSnackbar(CHANGES_SAVED_MESSAGE, { variant: 'success' });
      }
      return response;
    },
    [focusOnFirstError, onSubmitSuccess, selectedDate, updateCallFeed]
  );
  const onReset = useCallback(() => {
    reset();
  }, [reset]);

  const onDiscard = useCallback(() => {
    setEditing(false);
    unsavedWarningDialog.handleClose();
    continueEvent();
  }, [continueEvent, setEditing, unsavedWarningDialog]);

  const rationIngredientsMap = useMemo(
    () =>
      rations.reduce((acc, ration) => {
        acc.set(ration.id, ration.ingredients ?? []);
        return acc;
      }, new Map()),
    [rations]
  );

  const onContinueEditing = useCallback(async () => {
    await validateForm();
    unsavedWarningDialog.handleClose();
    // need to wait for the dialog to close before focusing on the first error
    isFocusOnFirstErrorAfterDialogClose.current = true;
  }, [unsavedWarningDialog, validateForm]);

  const onUnsavedSave = useCallback(async () => {
    await onSubmit(getValues());
    setEditing(false);
    unsavedWarningDialog.handleClose();
    continueEvent();
  }, [continueEvent, getValues, onSubmit, setEditing, unsavedWarningDialog]);

  const onPreventEvent = useCallback(async () => {
    await validateForm();
    unsavedWarningDialog.handleOpen();
  }, [unsavedWarningDialog, validateForm]);

  setPreventEvent(onPreventEvent);
  useEditContextPreventRouteChange();

  useEffect(() => {
    setEditing(isDirty);
  }, [isDirty, setEditing]);

  useEffect(() => {
    if (stale || !tz || pens.length === 0 || rationIngredientsMap.size === 0) {
      return;
    }

    (async () => {
      const kvValues = await callFeedKvStore.get();

      reset({
        ...getValues(),
        callBy: kvValues.callBy,
        graphShowDmi: kvValues.graphShowDmi,
        pens: pens.map((pen) => {
          const drops = (pen.drops ?? []).map((drop) => ({
            ...drop,
            numHeads: sumBy(filterPenLotsForDate(pen.penLots, new Date()), (pl) => pl.numHeads),
          }));
          const totalWeight = sumBy(drops, (d) => d.calledLbs ?? 0);
          const hasSameRation = new Set(drops.map((drop) => drop.rationId)).size === 1;

          return {
            ...pen,
            drops,
            bunkScore: pen.bunkScore ?? '',
            asFedPerHead: pen.numHeads ? totalWeight / pen.numHeads : 0,
            dmiPerHead: getDmiPerHeadByDrops(drops, rationIngredientsMap, true),
            totalWeight,
            selectedAllRationId: hasSameRation ? drops[0]?.rationId : undefined,
          };
        }),
      });
    })();
  }, [getValues, pens, rationIngredientsMap, reset, stale, tz]);

  const hasError = Object.keys(errors).length > 0;

  if (isTodaySelected && canDuplicateYesterdayDrops) {
    const fromDate = dayjs(dateOptions[0].value).subtract(1, 'day').format('YYYY-MM-DD');
    const toDate = dateOptions[0].value;
    return (
      <>
        <DuplicateDropsEmptyView
          isTodaySelected={isTodaySelected}
          fromDate={fromDate}
          toDate={toDate}
          onSuccess={onSubmitSuccess}
        />
        {isSuperAdmin && <DevToolDateOverride />}
      </>
    );
  }
  return (
    <FormProvider key="call-feeds-date-form" {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate ref={formRef} style={{ width: '100%', height: '100%' }}>
        <CallFeedTabFields data-testid="call-feed-tab">
          <CallFeedTabHeader>
            <DateSelect
              value={selectedDate}
              onChange={onDateSelected}
              options={dateOptions}
              iconTooltip="This toggle allows you to call feed for today or tomorrow"
            />
            {!isPhoneSize && isDirty && <UnsavedFlashMessage />}

            {!isDropsEmptyTomorrow && (
              <Box>
                <IconButton onClick={() => showCallFeedSettingsModal({})}>
                  <SettingsIcon />
                </IconButton>
                <Button
                  type="reset"
                  variant="text"
                  disabled={!isDirty}
                  data-testid="call-feed-tab-cancel-button"
                  onClick={onReset}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  type="submit"
                  disabled={!isDirty}
                  data-testid="call-feed-tab-save-button"
                >
                  {isPhoneSize ? 'Save' : 'Save Changes'}
                </LoadingButton>
              </Box>
            )}
          </CallFeedTabHeader>

          {isPhoneSize && isDirty && (
            <Box my={0} mx={2}>
              <UnsavedFlashMessage />
            </Box>
          )}

          {isDropsEmptyTomorrow ? (
            <DuplicateDropsEmptyView
              fromDate={dateOptions[0].value}
              toDate={dateOptions[1].value}
              onSuccess={onSubmitSuccess}
            />
          ) : (
            <BoxFlexOne>
              <Virtuoso
                data={pens}
                overscan={200} // Reduces the re-renders on scroll. Too large and form updates will be slow
                itemContent={(index: number, pen) => (
                  <PenDropsListWrap isPhoneSize={isPhoneSize}>
                    <PenDropsList
                      pens={pens}
                      penIndex={index}
                      rations={rations}
                      rationIngredientsMap={rationIngredientsMap}
                      isTodaySelected={isTodaySelected}
                      selectedDate={selectedDate}
                      formMethods={methods}
                      formRef={formRef}
                    />
                  </PenDropsListWrap>
                )}
              />
            </BoxFlexOne>
          )}
        </CallFeedTabFields>
      </form>
      {isSuperAdmin && <DevToolDateOverride />}
      <UnsavedWarningDialog
        hasError={hasError}
        saving={submitting}
        onClose={unsavedWarningDialog.handleClose}
        open={unsavedWarningDialog.open}
        onDiscard={onDiscard}
        onContinueEditing={onContinueEditing}
        onSave={onUnsavedSave}
        onExited={focusOnFirstErrorAfterDialogClose}
      />
    </FormProvider>
  );
};
