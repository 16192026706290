import { positionNameSorter } from '@/common/utils/sorters';
import { BTDevTool } from '@/components/Bluetooth/BTDevTool';
import BuildLoadsTab from '@/components/BuildLoadsTab/BuildLoadsTab';
import { EmptyFeedingScreen } from '@/components/BuildLoadsTab/EmptyFeedingScreen';
import BunkScoringTab from '@/components/BunkScoringTab/BunkScoringTab';
import { CallFeedTab, PenDrop } from '@/components/CallFeedTab';
import DashboardLayout from '@/components/DashboardLayout';
import { useEditContext, useEditContextPreventRouteChange } from '@/components/EditContext';
import LoadsAndFeedTab from '@/components/Feeding/LoadsAndFeedTab';
import { LoadingScreen } from '@/components/LoadingScreen';
import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';
import { TabNavList } from '@/components/TabNav/TabNavComponents';
import { TabNavPanel } from '@/components/TabNav/styledComponents';
import { today } from '@/components/helpers/date';
import { formatDateForDB } from '@/components/helpers/format';
import { useOnDayChange } from '@/components/hooks/useOnDayChange';
import {
  FeedingMethod,
  useGetFeedingPageDropsQuery,
  useGetFeedingPageLoadsQuery,
  useGetFeedingPagePensQuery,
  useGetFeedingPageRationsQuery,
} from '@/web-types';
import { Tab } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import Head from 'next/head';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BatchFeedingTab from '@/components/BatchFeeding/BatchFeedingTab';
import { useRouter } from 'next/router';
import { TabContainer } from '@/components/TabNav/TabContainer';
import { Tabs } from '@/components/Feeding/constants';
import type { localdate } from '@/common/models/types';
import { showAlertModal } from '@/components/AlertModal/AlertModal';
import Typography from '@mui/material/Typography';

const REFETCH_INTERVAL = 1000 * 30; // 30 seconds

gql`
  query GetFeedingPageRations {
    getRations {
      id
      name
      position
      currentRevision
      ingredients {
        id
        rationId
        rationRevision
        percentOfRationAsFed
        ingredient {
          id
          name
          current {
            percentDry
          }
        }
      }
    }
  }

  query GetFeedingPagePens(
    $date: LocalDate
    $withRations: Boolean = true
    $withNumHeads: Boolean = true
    $withBunkScoring: Boolean = true
  ) {
    getPens(withRations: $withRations, withNumHeads: $withNumHeads, withBunkScoring: $withBunkScoring) {
      id
      name
      penLots {
        id
        penId
        lotId
        numHeads
        fromDate
        toDate
        lot {
          id
          name
          sex
          inWeightLbs
          projectedCurrentWeightLbs
          projectedCurrentWeightPerHeadLbs
          daysOnFeed
          currentNumHeads
          processedOn
          deathLossCount
          totalSalesNumHeads
          averageDailyGain
          source
          numHeadsTreated
          estimatedSaleWeightPerHeadLbs
          estimatedAverageDailyGain
          numHeadsIn
          lotOwners {
            contact {
              name
            }
          }
        }
      }
      drops(date: $date) {
        id
        calledLbs
        desiredCalledLbs
        fedLbs
        rationId
        rationRevision
        penDropIndex
        penId
        date
        load {
          id
          stage
        }
      }
      bunkScoring {
        bunkScore
        bunkScoreYesterday
        fedLbsYesterday
        calledLbsYesterday
        varianceYesterday
      }
      rations {
        id
        name
      }
      numHeads
      projectedCurrentWeightPerHeadLbs
    }
  }

  query GetFeedingPageDrops($date: LocalDate, $yesterdayDate: LocalDate, $tomorrowDate: LocalDate) {
    todayDrops: getDropsByDate(date: $date) {
      id
      date
      loadId
      penDropIndex
      loadDropIndex
      calledLbs
      rationId
      rationRevision
      fedLbs
      pen {
        id
        name
        position
      }
      ration {
        id
        color
        name
        position
        currentRevision
        ingredients {
          id
          percentOfRationAsFed
          ingredient {
            id
            name
          }
        }
      }
    }

    yesterdayDrops: getDropsByDate(date: $yesterdayDate) {
      id
    }

    tomorrowDrops: getDropsByDate(date: $tomorrowDate) {
      id
    }
  }
  query GetFeedingPageLoads($date: LocalDate) {
    getLoadsByDate(date: $date) {
      id
      name
      date
      loadedLbs
      calledLbs
      loadedLbs
      stage
      fedLbs
      drops {
        id
        penDropIndex
        loadDropIndex
        calledLbs
        fedLbs
        loadId
        rationId
        rationRevision
        pen {
          id
          name
        }
        ration {
          id
          name
          color
          currentRevision
          ingredients {
            id
            percentOfRationAsFed
            ingredient {
              id
              name
              position
            }
          }
        }
      }
    }
  }
`;

const createDateOptions = (): { value: string; label: string }[] | null => {
  const today = dayjs.tz();
  const tomorrow = today.add(1, 'day');
  return [
    { value: formatDateForDB(today), label: today.format('ddd, D') },
    { value: formatDateForDB(tomorrow), label: tomorrow.format('ddd, D') },
  ];
};

export default function DashboardFeeding(): JSX.Element {
  const router = useRouter();
  const { editing } = useEditContext();
  useEditContextPreventRouteChange();
  const [dateOptions, setDateOptions] = useState(createDateOptions());
  const defaultDate = String(dateOptions?.find((item) => item.value === router.query.date)?.value ?? today());
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const [{ loaded, tz, settings }] = useOrgSettings();
  const feedingMethod = settings['feeding.feedingMethod'];
  const fetchingOrgSettings = !loaded;
  const hasBatchFeeding = feedingMethod == FeedingMethod.Batch;

  const yesterdayDate = dayjs.tz().subtract(1, 'day').format('YYYY-MM-DD');
  const tomorrowDate = dayjs.tz().add(1, 'day').format('YYYY-MM-DD');

  const [{ fetching: fetchingPens, data: pensData, stale: stalePensData }, refetchPens] = useGetFeedingPagePensQuery({
    variables: {
      date: selectedDate,
    },
  });

  const [{ fetching: fetchingLoads, data: loadsData }, refetchLoads] = useGetFeedingPageLoadsQuery({
    variables: {
      date: selectedDate,
    },
  });
  const [{ fetching: fetchingDrops, data: dropsData }, refetchDrops] = useGetFeedingPageDropsQuery({
    variables: {
      date: selectedDate,
      yesterdayDate,
      tomorrowDate,
    },
  });
  const [{ fetching: fetchingRations, data: rationsData }, refetchRations] = useGetFeedingPageRationsQuery();

  const onDateSelected = useCallback((date: localdate) => setSelectedDate(date), []);

  const loads = useMemo(() => {
    return loadsData?.getLoadsByDate?.filter((load) => load?.drops && load.drops.length > 0) ?? [];
  }, [loadsData?.getLoadsByDate]);

  const pens = useMemo(() => {
    const allLoadDrops = loads.flatMap((load) => load.drops);
    return (
      (pensData?.getPens ?? [])
        .map((pen) => {
          const penLots = pen.penLots ?? [];
          const numHeads = penLots.reduce((acc, lot) => acc + lot.numHeads, 0);

          return {
            ...pen,
            penLots: penLots.map((penLot) => ({
              ...penLot,
              toDate: penLot.toDate ? new Date(penLot.toDate) : null,
              fromDate: new Date(penLot.fromDate),
            })),
            numHeads,
            drops: (pen?.drops?.map((d) => {
              // we allow the user to modify fed drops in the UI, but on the server, this is stored separate in the desired called lbs
              // So if the server has a desired called lbs, then we'll use/show that instead on this screen
              const calledLbs = d.desiredCalledLbs ?? d.calledLbs;
              return {
                ...d,
                calledLbs,
                numHeads,
                lastOver0CalledLbs: calledLbs,
              };
            }) ?? []) as PenDrop[],
            bunkScore: pen?.bunkScoring?.bunkScore,
          };
        })
        .filter((pen) => {
          // Default filtering, always show pens with head
          if (pen.numHeads > 0) return true;

          // If no head, but there is at least one load, show the pen
          return allLoadDrops.some((loadDrop) => loadDrop?.pen?.id === pen.id);
        }) ?? []
    );
  }, [pensData?.getPens, loads]);

  const drops = useMemo(() => {
    const dropsByDate = dropsData?.todayDrops ?? [];
    return dropsByDate.sort((a, b) => positionNameSorter(a.pen!, b.pen!));
  }, [dropsData?.todayDrops]);
  const rations = useMemo(() => {
    const data = rationsData?.getRations ?? [];
    return data.sort(positionNameSorter);
  }, [rationsData?.getRations]);

  const canDuplicateYesterdayDrops = useMemo(() => {
    const yesterdayDropsExists = (dropsData?.yesterdayDrops ?? [])?.length > 0;
    const todayDropsEmpty = pens.flatMap((pen) => pen.drops ?? []).length === 0;
    return pens.length > 0 && yesterdayDropsExists && todayDropsEmpty;
  }, [dropsData?.yesterdayDrops, pens]);

  const onSubmitBuildLoadsTab = useCallback(() => {
    refetchLoads();
    refetchDrops();

    // NOTE: will need to update "Loads & Feeds" tab
  }, [refetchLoads, refetchDrops]);

  const onSubmitCallFeedTab = useCallback(() => {
    const tomorrowDropsExists = (dropsData?.tomorrowDrops ?? [])?.length > 0;
    const isTodaySelected = selectedDate === dateOptions?.[0].value;

    if (isTodaySelected && tomorrowDropsExists) {
      showAlertModal({
        title: 'Call Feed',
        type: 'warning',
        message: (
          <>
            <Typography>This change will only affect today&apos;s feed calls.</Typography>
            <Typography>You have already duplicated drops for tomorrow.</Typography>
            <Typography>To adjust tomorrow&apos;s feed calls instead, toggle to tomorrow.</Typography>
          </>
        ),
      });
    }

    setDateOptions(createDateOptions());
    refetchPens();

    // NOTE: will need to update "Build Loads" tab
    onSubmitBuildLoadsTab();
  }, [dateOptions, dropsData?.tomorrowDrops, onSubmitBuildLoadsTab, refetchPens, selectedDate]);

  const fulfilledCallFeed = rations.length > 0 && pens.length > 0;
  const fetchingCallFeed = fetchingOrgSettings || fetchingRations || fetchingPens;

  const fulfilledBuildLoads = loads.length > 0 && drops.length > 0;
  const fetchingBuildLoads = fetchingLoads || fetchingDrops;

  const fulfilledLoadsAndFeedTab = loads.length > 0;
  const fetchingLoadsAndFeedTab = fetchingLoads;

  const onDayChange = useCallback(() => {
    const dateOptions = createDateOptions();
    if (!dateOptions) {
      return;
    }
    const isTodaySelected = selectedDate === dateOptions[0].value;
    setDateOptions(dateOptions);
    refetchLoads();
    refetchDrops();

    switch (true) {
      case !isTodaySelected: // day change. yesterday Date is selected
      case isTodaySelected && pens.every((pen) => !pen.drops?.length): // day change. Today is selected and there are no drops
        setSelectedDate(dateOptions[0].value);
        refetchPens();
        break;
    }
  }, [pens, refetchDrops, refetchLoads, refetchPens, selectedDate]);

  useOnDayChange(
    onDayChange,
    1000 * 60 // add delay to wait for backend cron job to run. FYI, backend takes ~23s to complete
  );

  useEffect(() => {
    if (editing) {
      return;
    }
    refetchPens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    if (!tz) {
      return;
    }
    // when the timezone data finishes fetching, we need to update the date options
    setDateOptions(createDateOptions());
    // and select defaultDate
    setSelectedDate(defaultDate);
  }, [defaultDate, tz]);

  useEffect(() => {
    const doRefetch = () => {
      if (editing) {
        return;
      }
      refetchPens({ requestPolicy: 'network-only' });
      refetchLoads({ requestPolicy: 'network-only' });
      refetchDrops({ requestPolicy: 'network-only' });
      refetchRations({ requestPolicy: 'network-only' });
    };

    const intervalId = setInterval(doRefetch, REFETCH_INTERVAL);
    return () => {
      clearInterval(intervalId);
    };
  }, [editing, refetchDrops, refetchLoads, refetchPens, refetchRations]);

  const isFetching = !tz || fetchingPens || fetchingLoads || fetchingDrops || fetchingRations;

  const tabItems = useMemo(
    () =>
      [
        { label: 'Bunk Scoring', value: Tabs.BUNK_SCORING },
        { label: 'Call Feed', value: Tabs.CALL_FEED },
        {
          label: hasBatchFeeding ? 'Batch Feeding' : 'Build Loads',
          value: hasBatchFeeding ? Tabs.BATCH_FEEDING : Tabs.BUILD_LOADS,
        },
        hasBatchFeeding ? null : { label: 'Loads & Feed', value: Tabs.LOADS_AND_FEED },
      ].filter(Boolean) as { label: string; value: Tabs }[],
    [hasBatchFeeding]
  );

  // need org settings to determine which tabs to show
  if (fetchingOrgSettings) {
    return (
      <DashboardLayout>
        <Head>
          <title>Redbook - Feeding</title>
        </Head>
        <LoadingScreen />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Head>
        <title>Redbook - Feeding</title>
      </Head>
      {isFetching && <CircularProgress size={20} sx={{ mx: 1, position: 'absolute', left: 5, top: 10 }} />}
      <TabContainer defaultTab={Tabs.CALL_FEED} tabMap={Tabs} baseUrl="/dashboard/feeding">
        <TabNavList>
          {tabItems.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </TabNavList>
        <TabNavPanel value={Tabs.BUNK_SCORING}>
          {(!fetchingCallFeed || fulfilledCallFeed) && (
            <>
              {pens?.length ? (
                <BunkScoringTab pens={pens} onChange={refetchPens} />
              ) : (
                <EmptyFeedingScreen mainText="There are currently no Pens" subText="Create pens to get started" />
              )}
            </>
          )}
          {fetchingCallFeed && !fulfilledCallFeed && <LoadingScreen />}
        </TabNavPanel>
        <TabNavPanel value={Tabs.CALL_FEED}>
          {(!fetchingCallFeed || fulfilledCallFeed) && dateOptions != null && (
            <>
              {pens?.length ? (
                <CallFeedTab
                  canDuplicateYesterdayDrops={canDuplicateYesterdayDrops}
                  rations={rations}
                  pens={pens}
                  dateOptions={dateOptions}
                  selectedDate={selectedDate}
                  tz={tz}
                  stale={stalePensData || fetchingPens}
                  onDateSelected={onDateSelected}
                  onSubmit={onSubmitCallFeedTab}
                />
              ) : (
                <EmptyFeedingScreen mainText="There are currently no Pens" subText="Create pens to get started" />
              )}
            </>
          )}
          {fetchingCallFeed && !fulfilledCallFeed && <LoadingScreen />}
        </TabNavPanel>
        <TabNavPanel value={Tabs.BUILD_LOADS}>
          {(!fetchingBuildLoads || fulfilledBuildLoads) && dateOptions != null && (
            <BuildLoadsTab
              canDuplicateYesterdayDrops={canDuplicateYesterdayDrops}
              loads={loads}
              drops={drops}
              dateOptions={dateOptions}
              selectedDate={selectedDate}
              onDateSelected={onDateSelected}
              onSubmit={onSubmitBuildLoadsTab}
            />
          )}
          {fetchingBuildLoads && !fulfilledBuildLoads && <LoadingScreen />}
        </TabNavPanel>
        <TabNavPanel value={Tabs.BATCH_FEEDING}>
          <BatchFeedingTab
            canDuplicateYesterdayDrops={canDuplicateYesterdayDrops}
            dateOptions={dateOptions!}
            selectedDate={selectedDate}
            onDateSelected={onDateSelected}
          />
        </TabNavPanel>
        <TabNavPanel value={Tabs.LOADS_AND_FEED}>
          {(!fetchingLoadsAndFeedTab || fulfilledLoadsAndFeedTab) && (
            <>
              {loads?.length ? (
                <LoadsAndFeedTab loads={loads} />
              ) : (
                <EmptyFeedingScreen
                  mainText="There are currently no Loads"
                  subText="Go to Build Loads to get started"
                />
              )}
              <BTDevTool />
            </>
          )}
          {fetchingLoadsAndFeedTab && !fulfilledLoadsAndFeedTab && <LoadingScreen />}
        </TabNavPanel>
      </TabContainer>
    </DashboardLayout>
  );
}
