import {
  PenDropsListHeaderBunkScoreText,
  PenDropsListHeaderInfo,
  PenDropsListHeaderStatText,
} from '@/components/CallFeedTab/styledComponents';
import Typography from '@mui/material/Typography';
import { LinkButton } from '@/components/LinkButton/LinkButton';
import { InfoOutlined, Subject } from '@mui/icons-material';
import { showLotsInPenModal } from '@/components/CallFeedTab/LotsInPenModal';
import { Button } from '@mui/material';
import { showPenDropDetailsModal } from '@/components/CallFeedTab/PenDropDetailsModal';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { CalLFeedFormValues, PenFeed, RationType } from '@/components/CallFeedTab/types';
import type { localdate } from '@/common/models/types';
import { Control, UseFormReturn } from 'react-hook-form';
import { BunkScoreSelectField } from '@/components/CallFeedTab/BunkScoreSelectField';
import { useEffect, useMemo, useState } from 'react';
import { formatProjectedWeightLbs } from '@/components/helpers/format';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { SelectPicker } from '@/components/SelectPicker/SelectPicker';
import IconButton from '@mui/material/IconButton';
import { showLotInformationModal } from '@/components/CallFeedTab/LotInformationModal';
import { emDash } from '@/components/Typography/emDash';
import { SortableLotInfoBlock, sortableLotInfoBlocks } from '@/components/CallFeedTab/constants';
import useLocalStorage from '@/components/hooks/useLocalStorage';
import { PenLot } from '@/web-types';
import { Subset } from '@/common/types/subset';

export const Header = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  height: '46px',
  background: theme.palette.grey[300],
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));
Header.defaultProps = {
  direction: 'row',
  spacing: 2,
};

export const PenDropsListHeader = ({
  inDialog,
  pen,
  pens,
  penIndex,
  rations,
  rationIngredientsMap,
  selectedDate,
  isTodaySelected,
  formMethods,
  control,
  formRef,
}: {
  inDialog?: boolean;
  pen: PenFeed;
  pens: PenFeed[];
  penIndex: number;
  rations: RationType[];
  rationIngredientsMap: Map<number, RationType['ingredients']>;
  isTodaySelected?: boolean;
  selectedDate: localdate;
  formMethods: UseFormReturn<CalLFeedFormValues>;
  control: Control<CalLFeedFormValues, any>;
  formRef?: React.RefObject<HTMLFormElement>;
}) => {
  const isPhoneSize = useIsPhoneSize();
  const headerText = useMemo(() => {
    const result = [`${pen.numHeads ?? 0} Head`];
    if (pen.projectedCurrentWeightPerHeadLbs) {
      result.push(formatProjectedWeightLbs(pen.projectedCurrentWeightPerHeadLbs ?? 0, true, false));
    }
    if (isPhoneSize) result.length = 1;

    return result.join(' ｜ ');
  }, [isPhoneSize, pen.numHeads, pen.projectedCurrentWeightPerHeadLbs]);
  return (
    <Header>
      {inDialog ? (
        <InDialogHeader pen={pen} />
      ) : (
        <PenDropsListHeaderInfo flex={1}>
          <Box
            sx={{
              width: { xs: 'auto', md: 180 },
              color: 'primary.main',
              fontWeight: 600,
            }}
          >
            {pen.name}
          </Box>
          {!isPhoneSize && (
            <PenDropsListHeaderBunkScoreText data-testid="call-feed-pen-bunkScore-text">
              <BunkScoreSelectField control={control} penIndex={penIndex} isTodaySelected={isTodaySelected} />
            </PenDropsListHeaderBunkScoreText>
          )}
          <PenDropsListHeaderStatText data-testid="call-feed-pen-stat-text" flex={1}>
            <Typography sx={{ mr: 1 }}>{headerText} ｜</Typography>
            <LinkButton endIcon={<InfoOutlined />} onClick={() => showLotsInPenModal({ penId: pen.id })}>
              Lots
            </LinkButton>
          </PenDropsListHeaderStatText>

          {!isPhoneSize && (
            <Button
              variant="text"
              data-testid="call-feed-pen-show-details"
              onClick={() =>
                showPenDropDetailsModal({
                  pens,
                  penIndex,
                  rations,
                  rationIngredientsMap,
                  selectedDate,
                  isTodaySelected,
                  formMethods,
                  formRef,
                })
              }
            >
              Show Details
            </Button>
          )}
        </PenDropsListHeaderInfo>
      )}
    </Header>
  );
};

export const HeaderValue = styled(Box)(({ theme }) => ({
  color: theme.palette.customGrey.main,
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  fontSize: 13,
  marginRight: 30,
  marginLeft: 30,
  b: {
    color: 'black',
    display: 'inline-block',
    fontSize: 15,
  },
}));

const InDialogHeader = ({ pen }: { pen: PenFeed }) => {
  const [selectedPenLotId, setSelectedPenLotId] = useState<number>(pen.penLots?.[0]?.id ?? 0);
  const lotsOptions = (pen.penLots ?? []).map((pl) => ({
    label: pl.lot?.name,
    onClick: () => setSelectedPenLotId(pl.id),
  }));
  const selectedPenLot = (pen.penLots ?? []).find((pl) => pl.id === selectedPenLotId)!;

  const [sortedLotInfoBlocks, setSortedLotInfoBlocks] = useState<SortableLotInfoBlock[]>([]);
  const [lotInfoBlocksOrderLS] = useLocalStorage<string[]>('lotInfoBlocksOrder', []);

  useEffect(() => {
    setSortedLotInfoBlocks(
      sortableLotInfoBlocks.sort((a, b) => {
        const aIndex = lotInfoBlocksOrderLS.indexOf(a.label);
        const bIndex = lotInfoBlocksOrderLS.indexOf(b.label);
        return aIndex - bIndex;
      })
    );
  }, [lotInfoBlocksOrderLS]);

  return (
    <PenDropsListHeaderInfo display="flex" flex="1" justifyContent="space-between" pr={1} width="100%">
      <SelectPicker
        items={lotsOptions}
        text={selectedPenLot?.lot?.name}
        sx={{
          color: '#2DA6AE',
          background: '#E2FDFF',
          padding: '1px 7px',
          borderRadius: '7px',
          border: '1px solid rgba(45, 166, 174, 0.7)',
          '&:hover': {
            background: '#D5F9FF',
          },
        }}
      />

      <Box flexGrow={1} overflow="auto">
        <Box whiteSpace="nowrap" display="flex">
          {sortedLotInfoBlocks.map(({ shortLabel, renderValue }) => (
            <HeaderValue key={shortLabel}>
              {shortLabel}: <b>{selectedPenLot ? renderValue(selectedPenLot as unknown as Subset<PenLot>) : emDash}</b>
            </HeaderValue>
          ))}
        </Box>
      </Box>

      <Box>
        <IconButton
          onClick={() =>
            showLotInformationModal({
              penName: pen.name,
              penLots: pen.penLots,
              defaultPenLotId: selectedPenLotId,
              onSuccess: (lotId) => setSelectedPenLotId(lotId),
            })
          }
        >
          <Subject />
        </IconButton>
      </Box>
    </PenDropsListHeaderInfo>
  );
};
