import { LotSex } from '@/web-types';
import { capitalize } from 'lodash';

export const sexLabel = (sex: string, pluralize?: boolean) => {
  switch (sex) {
    case LotSex.Steers:
      return `Steer${pluralize ? 's' : ''}`;
    case LotSex.Heifers:
      return `Heifer${pluralize ? 's' : ''}`;
    case LotSex.Cows:
      return `Cow${pluralize ? 's' : ''}`;
    case LotSex.Bulls:
      return `Bull${pluralize ? 's' : ''}`;
    case LotSex.Mixed:
      return 'Mixed';

    default:
      return sex;
  }
};

export const riskLevelLabel = (riskLevel: string) => capitalize(riskLevel);
export const tagColorLabel = (tagColor: string) =>
  tagColor
    .split('_')
    .map((str) => capitalize(str))
    .join(' ');

export const breedTypeLabel = (breedType: string) =>
  breedType
    .split('_')
    .map((str) => capitalize(str))
    .join(' ');
