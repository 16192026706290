import { roundToNearestTenWithOrgSettings } from '@/components/CallFeedAmount/helpers';
import ControlledCheckboxField from '@/components/ControlledCheckboxField';
import ControlledSelectField from '@/components/ControlledSelectField';
import { DialogActions, DialogContainer, DialogTitle } from '@/components/Dialog';
import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';
import { formatLbs } from '@/components/helpers/format';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { LoadStage } from '@/web-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { AddLoadDialogProps, BuildLoadsFormType, BuildLoadsTabProps } from './types';

export default function AddLoadDialog({
  open,
  onClose,
  rationsUnassigned = [],
  drops,
}: AddLoadDialogProps): JSX.Element {
  const isPhoneSize = useIsPhoneSize();
  const { control, resetField, setValue, watch } = useFormContext<BuildLoadsFormType>();
  const loadsFieldArray = useFieldArray({ control, name: 'loads' });
  const dropIdToLoadIdMap = watch('dropIdToLoadIdMap');
  const selectedRationId = watch('addLoadDialog.rationId');
  const rationSelected = !!selectedRationId;
  const checkboxes = watch('addLoadDialog.checkboxes');
  const checkboxSelected = checkboxes.some((checkbox) => checkbox);
  const theme = useTheme();
  const [addedWeight, setAddedWeight] = useState(0);
  const [{ settings: orgSettings }] = useOrgSettings();

  const InputLabelProps = useMemo(
    () => ({
      ['data-testid']: 'build-loads-add-load-dialog-drop-checkbox',
      sx: {
        '.MuiFormControlLabel-label': {
          flex: 1,
        },
        '&:not(:first-of-type)': {
          borderTop: `1px solid ${theme.palette.grey[300]}`,
        },
      },
    }),
    [theme]
  );

  useEffect(() => {
    setAddedWeight(0);
  }, [rationSelected]);

  useEffect(() => {
    if (!open) {
      return;
    }
    resetField('addLoadDialog');
  }, [open, resetField]);

  useEffect(() => {
    // NOTE: form data needs to be reset when ration is changed
    resetField('addLoadDialog.checkboxes');
  }, [selectedRationId, resetField]);

  const handleAddLoad = () => {
    const loadId = uuidv4();
    const updatedMap = { ...dropIdToLoadIdMap };
    const dropsAdded = new Map<number, BuildLoadsTabProps['drops'][0]>();

    checkboxes.forEach((checkbox, index) => {
      if (checkbox) {
        const drop = drops[index];
        dropsAdded.set(drop.id, drop);
        updatedMap[drop.id] = loadId;
      }
    });

    resetField('addLoadDialog');
    setValue('dropIdToLoadIdMap', updatedMap);

    loadsFieldArray.append({
      _uuid: loadId,
      stage: LoadStage.NotYetStarted,
      drops: [...dropsAdded].map(([, drop]) => drop),
    });

    onClose();
  };

  return (
    <Dialog
      data-testid="build-loads-add-load-dialog"
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
      fullScreen={isPhoneSize}
    >
      <DialogContainer>
        <DialogTitle
          title="Add a New Load"
          onCloseClick={onClose}
          containerProps={{
            sx: {
              pb: isPhoneSize ? 1 : 3,
            },
          }}
        />
        <Stack spacing={isPhoneSize ? 1 : 3}>
          <ControlledSelectField
            name="addLoadDialog.rationId"
            label="Ration"
            control={control}
            required
            rules={{
              required: 'Please enter a Ration',
            }}
            data-testid="build-loads-add-load-dialog-ration-selector"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {rationsUnassigned.map((ration) => (
              <MenuItem key={ration.id} value={ration.id}>
                {ration.name}
              </MenuItem>
            ))}
          </ControlledSelectField>
          {rationSelected && (
            <>
              <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
                <Box>Total: {formatLbs(addedWeight)}</Box>
              </Stack>
              <FormGroup sx={{ mt: 1 }}>
                {drops.map((drop, index) =>
                  dropIdToLoadIdMap[drop.id] || drop.rationId !== selectedRationId ? null : (
                    <ControlledCheckboxField
                      key={drop.id}
                      name={`addLoadDialog.checkboxes.${index}`}
                      control={control}
                      InputLabelProps={InputLabelProps}
                      label={
                        <Stack
                          sx={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                          direction="row"
                        >
                          <Box sx={{ fontWeight: 600 }}>
                            {drop?.pen?.name} - Drop {(drop.penDropIndex ?? 0) + 1}
                          </Box>
                          <Box>{formatLbs(roundToNearestTenWithOrgSettings(drop.calledLbs, orgSettings))}</Box>
                        </Stack>
                      }
                      onChange={(event, checked) => {
                        if (checked) {
                          setAddedWeight(addedWeight + roundToNearestTenWithOrgSettings(drop.calledLbs, orgSettings));
                        } else {
                          setAddedWeight(addedWeight - roundToNearestTenWithOrgSettings(drop.calledLbs, orgSettings));
                        }
                      }}
                    />
                  )
                )}
              </FormGroup>
            </>
          )}
          <DialogActions>
            <Button
              data-testid="build-loads-add-load-dialog-cancel-button"
              variant="text"
              type="reset"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              data-testid="build-loads-add-load-dialog-create-button"
              onClick={handleAddLoad}
              type="button"
              disabled={!rationSelected || !checkboxSelected}
            >
              Create
            </Button>
          </DialogActions>
        </Stack>
      </DialogContainer>
    </Dialog>
  );
}
