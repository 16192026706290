import { SelectPicker } from '@/components/SelectPicker/SelectPicker';
import Typography from '@mui/material/Typography';
import { bunkScoreOptions } from '@/components/BunkScoringTab';
import { BunkScoreValue } from '@/web-types';
import { emDash } from '@/components/Typography/emDash';

export const BunkScorePicker = ({
  value,
  onChange,
}: {
  value?: BunkScoreValue | '' | null;
  onChange: (value: BunkScoreValue) => void;
}) => {
  const bunkScoreLabel = bunkScoreOptions.find((option) => option.value === value)?.label;
  return (
    <SelectPicker
      items={bunkScoreOptions.map((option) => ({
        label: option.label,
        onClick: () => onChange(option.value),
      }))}
      sx={{ color: (theme) => (bunkScoreLabel ? theme.palette.grey[800] : theme.palette.grey[600]), ml: 3 }}
      text={
        <Typography>
          Bunk Score: <b>{bunkScoreLabel || emDash}</b>
        </Typography>
      }
    />
  );
};
