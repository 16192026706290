/**
 * Signals code that should be unreachable.
 * If this code is reached in production, it will throw an error with the associated message.
 *
 * One use case of this is to allow easily including a message if you want to do a non-null assertion
 * ```
 *   const value = maybeValue ?? assertImpossible("maybeValue must be defined");
 *   //            ^^^^^^^^^^... this is type: number | undefined
 *   //    ^^^^^................ this is type: number
 * ```
 *
 * The benefit of this over just `!` is that this can show a useful error message
 * and can be used in any situation of unreachable code, not just nullability
 */
export function assertImpossible(message: any): never {
  throw new Error(`Assertion failure: ${message}`);
}
