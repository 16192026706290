import { roundToNearestTenWithOrgSettings } from '@/components/CallFeedAmount/helpers';
import { DialogContainer, DialogTitle } from '@/components/Dialog';
import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';
import { formatLbs } from '@/components/helpers/format';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { UnassignedDropsDialogProps } from './types';
import { RationWithColorTag } from '@/components/Inventory/RationWithColorTag';

const DropItem = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
}));

export default function UnassignedDropsDialog({
  open,
  onClose,
  rationsUnassigned = [],
}: UnassignedDropsDialogProps): JSX.Element {
  const isPhoneSize = useIsPhoneSize();
  const [{ settings: orgSettings }] = useOrgSettings();

  return (
    <Dialog
      data-testid="build-loads-unassigned-drops-dialog"
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
      fullScreen={isPhoneSize}
    >
      <DialogContainer>
        <DialogTitle title="Unassigned Drops" onCloseClick={onClose} containerProps={{ sx: { pb: 1 } }} />
        <Stack spacing={1}>
          {rationsUnassigned.map((ration) => {
            const total = ration.drops.reduce(
              (acc, drop) => acc + roundToNearestTenWithOrgSettings(drop.calledLbs, orgSettings),
              0
            );
            return (
              <Box key={ration.name}>
                <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <RationWithColorTag
                    ration={ration}
                    nameSx={{
                      fontWeight: 600,
                      fontSize: { sx: '14px', md: '16px' },
                      color: 'primary.main',
                    }}
                  />
                  <Box>Total: {formatLbs(total)}</Box>
                </Stack>
                <Box
                  sx={{
                    borderBottom: '1px solid #DFE2E6',
                    pb: 1,
                    mB: 1,
                  }}
                >
                  {ration.drops.map((drop) => (
                    <DropItem key={drop.id}>
                      <Stack direction="row" sx={{ pt: isPhoneSize ? 0 : 1, justifyContent: 'space-between' }}>
                        <Box sx={{ fontWeight: 600 }}>
                          {drop?.pen?.name} - Drop {(drop.penDropIndex ?? 0) + 1}
                        </Box>
                        <Box>{formatLbs(roundToNearestTenWithOrgSettings(drop.calledLbs, orgSettings))}</Box>
                      </Stack>
                    </DropItem>
                  ))}
                </Box>
              </Box>
            );
          })}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              pt: 2,
            }}
          >
            <Button data-testid="build-loads-unassigned-drops-dialog-ok-button" onClick={onClose}>
              Ok
            </Button>
          </Box>
        </Stack>
      </DialogContainer>
    </Dialog>
  );
}
