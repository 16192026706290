import { DialogTitle } from '@/components/Dialog';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import { Box, Dialog, Table, TableBody, TableCell, TableRow } from '@mui/material';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import { useGetPenLotsForDatesQuery } from '@/web-types';
import dayjs from 'dayjs';
import { LoadingScreen } from '@/components/LoadingScreen';
import { useMemo } from 'react';

type LotsInPenModalProps = {
  penId: number;
};

export const showLotsInPenModal = (props?: LotsInPenModalProps) => ModalManager.show(LotsInPenModal, props);

const LotsInPenModal = ModalManager.create<LotsInPenModalProps>(({ penId }) => {
  const modal = useModal();
  const isPhoneSize = useIsPhoneSize();
  const date = useMemo(() => dayjs.tz().toISOString(), []);
  const [{ data, fetching }] = useGetPenLotsForDatesQuery({
    variables: {
      periodStart: date,
      periodEnd: date,
      penId,
    },
  });

  return (
    <Dialog onClose={modal.remove} open={modal.visible} maxWidth={'sm'} fullWidth fullScreen={isPhoneSize}>
      <DialogTitle title="Lots in Pen" onCloseClick={modal.remove} containerProps={{ sx: { px: 4, pt: 4 } }} />
      <Box px={3} py={3} pb={5}>
        {fetching ? (
          <LoadingScreen />
        ) : (
          <Table>
            <TableBody>
              {data?.getPenLots?.map((pl) => {
                return (
                  <TableRow key={pl.id}>
                    <TableCell sx={{ fontWeight: 600 }}>{pl.lot?.name}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{pl.numHeads} head</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Box>
    </Dialog>
  );
});
