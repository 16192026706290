import { PenFeed } from '@/components/CallFeedTab/types';
import { SelectPicker } from '@/components/SelectPicker/SelectPicker';

export const PenPicker = ({
  pens,
  penIndex,
  setPenIndex,
}: {
  pens: PenFeed[];
  penIndex: number;
  setPenIndex?: (index: number) => void;
}) => {
  return (
    <SelectPicker
      caretPosition="left"
      items={pens.map((pen, index) => ({
        label: pen.name,
        onClick: () => setPenIndex?.(index),
      }))}
      sx={{ mr: 2, fontWeight: 600, fontSize: 17 }}
      text={pens[penIndex].name}
    />
  );
};
