import { roundToNearestTenWithOrgSettings } from '@/components/CallFeedAmount/helpers';
import ControlledCheckboxField from '@/components/ControlledCheckboxField';
import { DialogActions, DialogContainer, DialogTitle } from '@/components/Dialog';
import { useOrgSettings } from '@/components/OrgSettings/OrgSettings';
import { formatLbs } from '@/components/helpers/format';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AddDropDialogProps, BuildLoadsFormType, BuildLoadsTabProps } from './types';

const RationNameLabel = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
}));
const DropSelectLabelContainer = styled(Stack)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));
DropSelectLabelContainer.defaultProps = {
  direction: 'row',
};
const DropSelectLabelNameAndIndex = styled(Box)(() => ({
  fontWeight: 600,
}));

const DropSelectLabel = ({ drop }: { drop: AddDropDialogProps['drops'][0] }) => {
  const [{ settings: orgSettings }] = useOrgSettings();
  return (
    <DropSelectLabelContainer>
      <DropSelectLabelNameAndIndex>
        {drop?.pen?.name} - Drop {(drop.penDropIndex ?? 0) + 1}
      </DropSelectLabelNameAndIndex>
      <Box>{formatLbs(roundToNearestTenWithOrgSettings(drop.calledLbs, orgSettings))}</Box>
    </DropSelectLabelContainer>
  );
};

const AddDropDialogForm = ({
  open,
  onClose,
  loadIndex,
  drops,
}: Omit<AddDropDialogProps, 'loadIndex'> & { loadIndex: number }) => {
  const [{ settings: orgSettings }] = useOrgSettings();
  const isPhoneSize = useIsPhoneSize();
  const { control, getValues, resetField, setValue, watch } = useFormContext<BuildLoadsFormType>();
  const load = watch(`loads.${loadIndex}`);
  const ration = watch(`loads.${loadIndex}.drops.0.ration`);
  const checkboxes = watch(`addDropDialog.checkboxes`);
  const dropIdToLoadIdMap = watch(`dropIdToLoadIdMap`);
  const { append } = useFieldArray({ control, name: `loads.${loadIndex}.drops` });
  const theme = useTheme();
  const loadDrops = getValues(`loads.${loadIndex}.drops`) ?? [];
  const totalCalledLbs = loadDrops.reduce(
    (total, drop) => total + roundToNearestTenWithOrgSettings(drop.calledLbs, orgSettings),
    0
  );
  const [addedWeight, setAddedWeight] = useState(0);

  const DropSelectLabelInputLabelProps = useMemo(
    () => ({
      sx: {
        '.MuiFormControlLabel-label': {
          flex: 1,
        },
        '&:not(:first-of-type)': {
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          mt: 1,
          pt: 1,
        },
      },
    }),
    [theme.palette.grey]
  );

  useEffect(() => {
    setAddedWeight(0);
  }, [open]);

  useEffect(() => {
    if (!open) {
      return;
    }
    resetField('addDropDialog.checkboxes');
  }, [open, resetField]);

  const handleAddDrop = useCallback(() => {
    const loadId = load.id || load._uuid;
    const updatedMap = { ...dropIdToLoadIdMap };
    const dropsAdded: BuildLoadsTabProps['drops'][0][] = [];
    checkboxes.forEach((checkbox, index) => {
      if (checkbox) {
        const drop = {
          ...drops[index],
          loadId: load.id || null,
        };
        dropsAdded.push(drop);
        updatedMap[drop.id] = loadId;
      }
    });

    setValue('dropIdToLoadIdMap', updatedMap);

    dropsAdded.forEach((drop) => {
      append(drop);
    });
    resetField('addDropDialog.checkboxes');
    onClose();
  }, [append, checkboxes, dropIdToLoadIdMap, drops, load, onClose, resetField, setValue]);

  return (
    <Dialog
      data-testid="build-loads-add-drops-dialog"
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
      fullScreen={isPhoneSize}
    >
      <DialogContainer>
        <DialogTitle
          title={`Add drops to Load ${loadIndex + 1}`}
          onCloseClick={onClose}
          containerProps={{
            sx: {
              pb: isPhoneSize ? 1 : 3,
            },
          }}
        />
        <Stack spacing={isPhoneSize ? 1 : 3}>
          <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <RationNameLabel>{ration?.name}</RationNameLabel>
            <Box>Total: {formatLbs(totalCalledLbs + addedWeight)}</Box>
          </Stack>

          <FormGroup>
            {drops.map((drop, index) =>
              dropIdToLoadIdMap[drop.id] || drop.rationId !== ration?.id ? null : (
                <ControlledCheckboxField
                  key={drop.id}
                  name={`addDropDialog.checkboxes.${index}`}
                  control={control}
                  InputLabelProps={DropSelectLabelInputLabelProps}
                  label={<DropSelectLabel drop={drop} />}
                  onChange={(event, checked) => {
                    if (checked) {
                      setAddedWeight(addedWeight + roundToNearestTenWithOrgSettings(drop.calledLbs, orgSettings));
                    } else {
                      setAddedWeight(addedWeight - roundToNearestTenWithOrgSettings(drop.calledLbs, orgSettings));
                    }
                  }}
                />
              )
            )}
          </FormGroup>
          <DialogActions>
            <Button
              data-testid="build-loads-add-drops-dialog-cancel-button"
              variant="text"
              type="reset"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button data-testid="build-loads-add-drops-dialog-create-button" type="button" onClick={handleAddDrop}>
              Create
            </Button>
          </DialogActions>
        </Stack>
      </DialogContainer>
    </Dialog>
  );
};

export const AddDropDialog = (props: AddDropDialogProps) => {
  if (props.loadIndex === null) {
    return null;
  }
  return <AddDropDialogForm {...props} loadIndex={props.loadIndex} />;
};
