import { Control } from 'react-hook-form';
import { CalLFeedFormValues } from '@/components/CallFeedTab/types';
import ControlledSelectField from '@/components/ControlledSelectField';
import { MenuItem } from '@mui/material';
import { bunkScoreOptions } from '@/components/BunkScoringTab';

export const BunkScoreSelectField = ({
  isTodaySelected,
  penIndex,
  control,
}: {
  isTodaySelected?: boolean;
  penIndex: number;
  control: Control<CalLFeedFormValues, any>;
}) => {
  if (!isTodaySelected) {
    return null;
  }
  return (
    <ControlledSelectField
      placeholder="Bunk Score"
      control={control}
      name={`pens.${penIndex}.bunkScore`}
      data-testid={`pens.${penIndex}.bunkScore`}
    >
      <MenuItem key="empty" value={''}>
        <em>-</em>
      </MenuItem>

      {bunkScoreOptions.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          data-testid={`bunk-scoring-tab-bunk-score-select-value-${penIndex}-${option.value}`}
        >
          {option.label}
        </MenuItem>
      ))}
    </ControlledSelectField>
  );
};
