import { DialogActions, DialogContainer, DialogContent } from '@/components/Dialog';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import { LoadingButton } from '@mui/lab';
import { ReactNode } from 'react';
import { Error, CheckCircle } from '@mui/icons-material';
import { ModalProps } from '@mui/material/Modal';
import { useIsPhoneSize } from '../hooks/useResponsive';

interface AlertModalProps {
  type?: 'success' | 'error' | 'warning';
  title?: string;
  message: string | ReactNode;
  okButton?: string;
  onOk?: () => void;
  modalSize?: 'sm' | 'md' | 'lg';
}

export const showAlertModal = (props: AlertModalProps) => ModalManager.show(AlertModal, props);

const AlertModal = ModalManager.create<AlertModalProps>(
  ({ title, message, okButton = 'Ok', onOk, type, modalSize }) => {
    const modal = useModal();
    const isPhoneSize = useIsPhoneSize();

    // This is to prevent the modal from closing when clicking on the backdrop
    const onClose: ModalProps['onClose'] = (event, reason) => {
      if (reason && reason === 'backdropClick') return;

      modal.remove();
    };

    return (
      <Dialog onClose={onClose} open={modal.visible} maxWidth={modalSize ?? 'xs'} fullWidth fullScreen={isPhoneSize}>
        <DialogContainer>
          <DialogContent sx={{ py: 2 }}>
            {type == 'success' && (
              <CheckCircle sx={{ fontSize: 40, mb: 1, color: (theme) => theme.palette.success.main }} />
            )}
            {type == 'error' && <Error sx={{ fontSize: 40, mb: 1, color: (theme) => theme.palette.error.main }} />}
            {type == 'warning' && <Error sx={{ fontSize: 40, mb: 1, color: (theme) => theme.palette.yellow[400] }} />}
            <Typography variant="h2" sx={{ pb: 1 }}>
              {title}
            </Typography>
            <Typography>{message}</Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <LoadingButton
              data-testid="alert-modal-ok-button"
              onClick={() => {
                onOk?.();
                modal.remove();
              }}
            >
              {okButton}
            </LoadingButton>
          </DialogActions>
        </DialogContainer>
      </Dialog>
    );
  }
);
