import '@/common/utils/date';
import dayjs from 'dayjs';
import { localdate } from '../models/types';

/**
 * Filter a list of pen lots to only those that are active at the time of a drop
 * @param penLots A list of pen lots, in any order
 * @param drop The drop to filter by
 * @returns A list of pen lots that are active at the time of the drop
 */
export const filterPenLotsForDrop = <T extends { fromDate: Date; toDate: Date | null; penId: number }>(
  penLots: T[] | null | undefined,
  drop: { date: localdate; fedOn: Date | null; penId: number },
  tz: string
): T[] => {
  const fedOn = drop.fedOn ?? dayjs.tz(drop.date, tz).endOf('day').toDate();
  const onlyDropPens = (penLots ?? []).filter((penLot) => penLot.penId === drop.penId);
  return filterPenLotsForDate(onlyDropPens, fedOn);
};

/**
 * Return the pen lots that are active on the given date
 */
export const filterPenLotsForDate = <T extends { fromDate: Date; toDate: Date | null }>(
  penLots: T[] | null | undefined,
  dateTime: Date
): T[] => {
  return (penLots ?? []).filter((penLot) => {
    return dateTime >= penLot.fromDate && (penLot.toDate == null || dateTime < penLot.toDate);
  });
};

/**
 * Return the pen lots that were active just before the given date
 */
export const filterPenLotsJustBeforeDate = <T extends { fromDate: Date; toDate: Date | null }>(
  penLots: T[] | null | undefined,
  dateTime: Date
): T[] => {
  // same logic as `filterPenLotsForDate` but inequalities are reversed
  return (penLots ?? []).filter((penLot) => {
    return dateTime > penLot.fromDate && (penLot.toDate == null || dateTime <= penLot.toDate);
  });
};

/**
 * Return the pen lots that are active within the given date range
 */
export const filterPenLotsForDateRange = <T extends { fromDate: Date; toDate: Date | null }>({
  penLots,
  fromDate,
  toDate,
}: {
  penLots: T[] | null | undefined;
  fromDate?: Date | null;
  toDate?: Date | null;
}): T[] => {
  return (penLots ?? []).filter((penLot) => {
    if (fromDate && penLot.toDate && penLot.toDate < fromDate) {
      return false;
    }
    if (toDate && penLot.fromDate > toDate) {
      return false;
    }
    return true;
  });
};
