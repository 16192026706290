import { Controller, Path, UseControllerProps } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { DraggableList, DraggableListProps } from '@/components/DraggableList/DraggableList';

export type ControlledDraggableListProps<TFieldValues extends FieldValues = FieldValues> =
  UseControllerProps<TFieldValues> &
    Omit<DraggableListProps, 'name'> & {
      name: Path<TFieldValues>;
      onChange?: DraggableListProps['onChange'];
    };

export default function ControlledDraggableList<TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  defaultValue,
  rules = {},
  onChange,
  ...componentProps
}: ControlledDraggableListProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const { value } = field;
        return (
          <DraggableList
            {...componentProps}
            {...field}
            defaultValue={value}
            onChange={(items) => {
              field.onChange(items);
              onChange?.(items);
            }}
          />
        );
      }}
    />
  );
}
