import { sumBy } from 'lodash';
import { localdate } from '@/common/models/types';
import { assertImpossible } from '../utils/assertImpossible';
import { Prisma } from '@prisma/client';
import { memoized } from '../utils/memoized';

export type RationToDryMatterRatio = (rationId: number) => number;

type Ingredient =
  | {
      ingredientHistories: readonly {
        fromDate: localdate;
        toDate: localdate | null;
        percentDry: number;
      }[];
    }
  | {
      current: {
        percentDry: number;
      };
    };

type Ration = {
  id: number;
  name: string;
  currentRevision: number;
  // ration ingredients
  ingredients: readonly {
    rationRevision: number;
    percentOfRationAsFed: number;
    ingredient: Ingredient;
  }[];
};

/** Returns a function that will calculate the dry matter ratio of an ingredient for a given date of feeding */
export function ingredientDryMatterRatio(feedDate?: localdate): (ingredient: Ingredient) => number {
  return feedDate
    ? (i) =>
        'ingredientHistories' in i
          ? i.ingredientHistories.find((h) => h.fromDate <= feedDate && (h.toDate === null || h.toDate > feedDate))!
              .percentDry
          : i.current.percentDry
    : (i) => ('current' in i ? i.current.percentDry : i.ingredientHistories.find((h) => h.toDate === null)!.percentDry);
}

export function getRationCurrentRevisionToDryMatterRatio(
  rations: Ration[],
  feedDate?: localdate
): RationToDryMatterRatio {
  const map = new Map(
    rations.map((ration) => {
      const calcDmRatio = () => {
        // get the current ingredients for this ration
        const revision = ration.currentRevision;
        const ingredients = ration.ingredients.filter((ingredient) => ingredient.rationRevision === revision);
        const wholePct = Math.round(sumBy(ingredients, (ingredient) => ingredient.percentOfRationAsFed * 100));
        if (100 != wholePct)
          throw new Error(`ingredients of ration ${ration.name} sum to ${wholePct}% instead of 100%`);
        const ingredientDmRatioForDate = ingredientDryMatterRatio(feedDate);
        const dmRatio = sumBy(
          ingredients,
          (ingredient) => ingredientDmRatioForDate(ingredient.ingredient) * ingredient.percentOfRationAsFed
        );
        return dmRatio;
      };
      const getOrCalcDmRatio = memoized(calcDmRatio);
      return [ration.id, getOrCalcDmRatio];
    })
  );
  return (id) =>
    (map.get(id) ?? assertImpossible(`tried to get dry matter ratio for a ration ${id} that was not found`))();
}

// The fields that should be selected for a ration in order to calculate the dry matter ratio
export const rationSelectForDmi = {
  id: true,
  name: true,
  currentRevision: true,
  ingredients: {
    select: {
      rationRevision: true,
      percentOfRationAsFed: true,
      ingredient: {
        select: {
          ingredientHistories: {
            select: {
              fromDate: true,
              toDate: true,
              percentDry: true,
            },
            orderBy: {
              fromDate: 'asc',
            },
          },
        },
      },
    },
  },
} satisfies Prisma.RationSelect;
