import dayjs from 'dayjs';
import { formatDate } from '@/components/helpers/format';
import { isNumber } from 'lodash';

export const getShrinkString = ({
  inWeightLbs,
  payWeightLbs,
}: {
  inWeightLbs?: number | null;
  payWeightLbs?: number | null;
}): string => {
  if (
    inWeightLbs === null ||
    inWeightLbs === undefined ||
    payWeightLbs === null ||
    payWeightLbs === undefined ||
    payWeightLbs === 0
  ) {
    return '';
  }

  inWeightLbs = inWeightLbs ?? 0; // 12
  payWeightLbs = payWeightLbs ?? 0; // 122

  const value = (((payWeightLbs - inWeightLbs) / payWeightLbs) * 100).toFixed(2);
  return `${value}%`;
};

interface GetEstimateSaleDateArgs {
  estimatedSaleWeightPerHeadLbs: number | null | undefined;
  estimatedAverageDailyGain: number | null | undefined;
  inWeightPerHeadLbs: number | null | undefined;
  processedOn: dayjs.Dayjs | string | null | undefined;
}

export const getEstimatedSaleDateString = (args: GetEstimateSaleDateArgs): string | undefined => {
  const date = getEstimatedSaleDate(args);
  return date ? formatDate(date) : undefined;
};

const getEstimatedSaleDate = ({
  estimatedSaleWeightPerHeadLbs,
  estimatedAverageDailyGain,
  inWeightPerHeadLbs,
  processedOn,
}: GetEstimateSaleDateArgs): dayjs.Dayjs | null => {
  if (
    estimatedAverageDailyGain === null ||
    estimatedAverageDailyGain === undefined ||
    estimatedAverageDailyGain === 0
  ) {
    return null;
  }

  if (
    !isNumber(estimatedSaleWeightPerHeadLbs) ||
    !isNumber(estimatedAverageDailyGain) ||
    !isNumber(inWeightPerHeadLbs)
  ) {
    return null;
  }

  processedOn = dayjs(processedOn);

  // (Sale Weight Per Head - In Weight Per Head) / Average Daily Gain
  const days = Math.floor((estimatedSaleWeightPerHeadLbs - inWeightPerHeadLbs) / estimatedAverageDailyGain);
  return processedOn.add(days, 'days');
};
