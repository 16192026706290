import { DialogTitle } from '@/components/Dialog';
import { useIsPhoneSize } from '@/components/hooks/useResponsive';
import Dialog from '@mui/material/Dialog';
import ModalManager, { useModal } from '@ebay/nice-modal-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { PenFeed } from '@/components/CallFeedTab/types';
import { MenuItem, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import SelectField from '@/components/SelectField';
import { useState } from 'react';
import { LoadingScreen } from '@/components/LoadingScreen';
import { sortableLotInfoBlocks } from '@/components/CallFeedTab/constants';
import { Subset } from '@/common/types/subset';
import { PenLot } from '@/web-types';

interface LotInformationModalProps {
  penName: string;
  penLots: PenFeed['penLots'];
  defaultPenLotId: number;
  onSuccess?: (lotId: number) => void;
}

export const showLotInformationModal = (props?: LotInformationModalProps) =>
  ModalManager.show(LotInformationModal, props);

const LotInformationModal = ModalManager.create<LotInformationModalProps>(
  ({ penName, penLots, defaultPenLotId, onSuccess }) => {
    const modal = useModal();
    const isPhoneSize = useIsPhoneSize();

    const [penLotId, setPenLotId] = useState<number>(defaultPenLotId);
    const penLotOptions = penLots?.map((pl) => ({ value: pl.id, label: pl.lot?.name })) ?? [];
    const penLot = penLots?.find((pl) => pl.id === penLotId);

    const onDone = () => {
      modal.remove();
      onSuccess?.(penLotId);
    };

    return (
      <Dialog onClose={modal.remove} fullScreen={isPhoneSize} open={modal.visible} maxWidth="sm" fullWidth>
        <Box p={4}>
          <DialogTitle title={`${penName} - Lot Information`} onCloseClick={modal.remove} />

          <SelectField value={penLotId} onChange={(e) => setPenLotId(e.target.value as number)} sx={{ my: 2 }}>
            {penLotOptions.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </SelectField>
          {!penLot ? (
            <LoadingScreen />
          ) : (
            <TableContainer sx={{ mb: 5 }}>
              <Table size="small" sx={{ tableLayout: 'fixed' }}>
                <TableBody>
                  {sortableLotInfoBlocks.map(({ label, renderValue }) => (
                    <TableRow key={label}>
                      <TableCell sx={{ width: '50%' }}>{label}</TableCell>
                      <TableCell>
                        <b>{penLot ? renderValue(penLot as unknown as Subset<PenLot>) : null}</b>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => onDone()}>Done</Button>
          </Box>
        </Box>
      </Dialog>
    );
  }
);
